import type { ResultCard as ResultCardType } from '@sh24/contentful-service'
import { ResultCard as ResultCardComponent } from '@sh24/ui-components'
import { useRichTextDocumentContext } from '../../contexts/rich-text-document-context'
import ReplacementToken from '../ReplacementToken/replacement-token'
import conditionalTextBlockEntriesToReactComponents from '../../utils/conditional-text-block-entries-to-react-components'

const outcomeColors = {
  negative: '#C9FAC8',
  positive: '#FFC7CC',
  reactive: '#FFF1BF',
  action: '#EBF2F2',
}

const getOutcomeColor = (outcome: string) => {
  if (outcome === 'negative' || outcome === 'positive' || outcome === 'reactive') {
    return outcomeColors[outcome]
  }
  return outcomeColors.action
}

const ResultCard = ({
  resultCard,
  openByDefault = false,
}: {
  resultCard: ResultCardType,
  openByDefault?: boolean,
}) => {
  const context = useRichTextDocumentContext()

  return (
    <ResultCardComponent
      infection={(<ReplacementToken contextKey="infection" useTextFrom="translation" translationPath="replacementTokens.results" />)}
      site={context.site ? (<ReplacementToken contextKey="site" useTextFrom="translation" translationPath="replacementTokens.results" />) : null}
      pillText={<ReplacementToken contextKey="outcome" useTextFrom="translation" translationPath="replacementTokens.results" />}
      pillBackgroundColour={getOutcomeColor(context.outcome as string)}
      topSection={conditionalTextBlockEntriesToReactComponents(resultCard.summarySections, context)}
      bottomSection={conditionalTextBlockEntriesToReactComponents(resultCard.whatNextSections, context)}
      openByDefault={openByDefault}
    />
  )
}

export default ResultCard
