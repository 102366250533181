import { ReactNode, Fragment } from 'react'
import type { ConditionalTextBlock as ConditionalTextBlockType } from '@sh24/contentful-service'
import satisfiesConditions from '../order-journeys/_v2/helpers/satisfies-conditions'
import documentToReactComponents from './document-to-react-components'

const conditionalTextBlockEntriesToReactComponents = (
  blocks: Array<ConditionalTextBlockType>,
  ctx: Record<string, ReactNode>,
) => blocks
  .filter((block) => satisfiesConditions(ctx, block.conditions))
  .map((block) => (
    <Fragment key={block.id}>
      {documentToReactComponents(block.content)}
    </Fragment>
  ))

export default conditionalTextBlockEntriesToReactComponents
