import { parseValue } from '@sh24/contentful-service/utils'
import comparisons from './comparisons'

const satisfiesConditions = (ctx, conditions) => {
  if (!conditions?.length) return true

  return conditions.every((condition) => {
    const { contextKey, comparator, value } = condition
    const compare = comparisons[comparator]
    const leftValue = parseValue(ctx[contextKey])
    const rightValue = parseValue(ctx[value]) || value

    return compare ? compare(leftValue, rightValue) : false
  })
}

export default satisfiesConditions
